import React, { useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useAuth } from "../../contexts/authContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import AnimatedGrid2 from "../landing/AnimatedGrid2";
import { ReactTyped } from "react-typed";

const Home = () => {
  const { currentUser, isPro } = useAuth();
  const [problemsCompleted, setProblemsCompleted] = useState(0);

  useEffect(() => {
    // Scroll to the middle of the screen when the component mounts
    const scrollToMiddle = () => {
      window.scrollTo({
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        behavior: "smooth",
      });
    };

    scrollToMiddle();

    // Fetch user's progress from local storage
    const storedProblems = JSON.parse(localStorage.getItem("problems"));
    if (storedProblems) {
      const completedCount = storedProblems.filter(
        (problem) => problem.completed
      ).length;
      setProblemsCompleted(completedCount);
    }
  }, []);

  // Define milestone messages
  const getIntroMessage = () => {
    if (problemsCompleted === 0) {
      return "Welcome! Ready to dive into quant finance? Let's get started!";
    } else if (problemsCompleted > 0 && problemsCompleted <= 10) {
      return "Great to see you back! Keep pushing through those problems!";
    } else if (problemsCompleted > 10 && problemsCompleted <= 50) {
      return "Impressive progress! You're getting closer to mastering quant finance.";
    } else if (problemsCompleted > 50 && problemsCompleted <= 100) {
      return "Fantastic work! Your dedication is paying off.";
    } else {
      return "You're a quant finance wizard! Keep up the excellent work.";
    }
  };

  return (
    <div className="relative z-10 py-16 text-gray-300 bg-gray-950 sm:mb-0 sm:h-auto">
      <AnimatedGrid2 />
      <div className="max-w-screen-lg mx-auto">
        <div className="pt-6 text-3xl font-bold sm:text-4xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-blue-500 to-purple-500 from-10% via-20% to-80%">
          <div className="h-12">
            {" "}
            {/* Adjust the height as needed */}
            <ReactTyped
              strings={["Welcome Back!", "Start Practicing Now!"]}
              typeSpeed={80}
              backSpeed={60}
              startDelay={500}
              showCursor={false}
            />
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, ease: "easeOut", delay: 1 }}
        >
          <p className="mt-2 text-lg font-light">{getIntroMessage()}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 sm:gap-6"
        >
          <div className="relative group">
            {/* <div className="absolute inset-0 z-0 w-full h-full transition-transform duration-200 transform bg-gray-900 rounded-lg shadow-lg"></div> */}
            <div className="relative z-10 p-4 transition-all duration-200 transform border border-gray-500 rounded-lg shadow-lg bg-gray-950 hover:border-gray-300 sm:p-6 group-hover:shadow-2xl">
              <h2 className="text-xl font-semibold text-green-300 transition-all duration-200 sm:text-2xl group-hover:text-green-400">
                Practice Problems
              </h2>
              <p className="mt-2 font-thin">
                Access a variety of quant interview problems to improve your
                skills.
              </p>
              <Link
                to="/practice-problems"
                className="inline-block px-4 py-2 mt-4 font-bold text-black transition-all duration-300 bg-green-500 shadow-sm hover:scale-105 hover:text-white hover:bg-green-400 rounded-xl hover:shadow-lg group/link"
              >
                Start Practicing
                <FaArrowRightLong className="inline-block ml-2 group-hover:scale-110 group-hover:-rotate-45 group-hover/link:translate-x-1 group-hover/link:-translate-y-1 group-hover/link:rotate-45 arrow-animation" />
              </Link>
            </div>
          </div>

          <div className="relative group">
            {/* <div className="absolute inset-0 z-0 w-full h-full transition-transform duration-200 transform bg-gray-700 rounded-lg shadow-lg"></div> */}
            <div className="relative z-10 p-4 transition-all duration-200 transform border border-gray-500 rounded-lg shadow-lg bg-gray-950 hover:border-gray-300 sm:p-6 group-hover:shadow-2xl">
              <h2 className="text-xl font-semibold text-blue-400 transition-all duration-200 group-hover:text-blue-500 sm:text-2xl">
                Progress Tracker
              </h2>
              <p className="mt-2 font-thin">
                {problemsCompleted === 0 ? (
                  <span>
                    No problems completed yet. View practice problems to get
                    started!
                  </span>
                ) : problemsCompleted === 1 ? (
                  <span>
                    You've completed 1 problem so far. Try working on some new
                    questions!
                  </span>
                ) : (
                  <span>
                    You've completed {problemsCompleted} problems so far. Great
                    work, keep up the momentum!
                  </span>
                )}
              </p>
              <Link
                to="/progress"
                className="inline-block px-4 py-2 mt-4 font-bold text-black transition-all duration-300 bg-blue-500 shadow-sm hover:scale-105 hover:text-white hover:bg-blue-400 rounded-xl hover:shadow-lg group/link"
              >
                View Progress
                <FaArrowRightLong className="inline-block ml-2 group-hover:scale-110 group-hover:-rotate-45 group-hover/link:translate-x-1 group-hover/link:-translate-y-1 group-hover/link:rotate-45 arrow-animation" />
              </Link>
            </div>
          </div>

          <div className="relative group sm:col-span-2">
            {/* <div className="absolute inset-0 z-0 w-full h-full transition-transform duration-200 transform bg-gray-700 rounded-lg shadow-lg"></div> */}
            <div className="relative z-10 p-4 transition-all duration-200 transform border border-gray-500 rounded-lg shadow-lg bg-gray-950 hover:border-gray-300 sm:p-6 group-hover:shadow-2xl">
              <h2 className="text-xl font-semibold text-purple-400 transition-all duration-200 sm:text-2xl group-hover:text-purple-500">
                Performance Analytics
              </h2>
              <p className="mt-2 font-thin">
                Track your performance and identify areas for improvement.
              </p>
              <Link
                to="/analytics"
                className="inline-block px-4 py-2 mt-4 font-bold text-black transition-all duration-300 bg-purple-500 shadow-sm hover:scale-105 hover:text-white hover:bg-purple-400 rounded-xl hover:shadow-lg group/link"
              >
                View Analytics
                <FaArrowRightLong className="inline-block ml-2 group-hover:scale-110 group-hover:-rotate-45 group-hover/link:translate-x-1 group-hover/link:-translate-y-1 group-hover/link:rotate-45 arrow-animation" />
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
