export const quantQuestionsVersion = 8;

export const quantPracticeQuestions = [
  {
    id: 1,
    title: "3 Heads in 5 Tosses",
    difficultyScore: 3,
    difficulty: "Easy",
    userDifficultyRatings: [3],
    description:
      "What is the probability of getting exactly 3 heads in 5 tosses of a fair coin?",
    solution:
      "The probability is calculated using the binomial distribution formula: P(X = k) = (n choose k) * (p^k) * ((1-p)^(n-k)), where n = 5, k = 3, p = 0.5. Thus, the probability is 0.3125.",
    hints: [
      "Use the binomial distribution formula",
      "Consider n = 5, k = 3, p = 0.5",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Binomial Distribution"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify the parameters n, k, p",
      "Apply the formula",
      "Calculate the result",
    ],
    isPro: false,
  },
  {
    id: 2,
    title: "Expected Value of Dice Sum",
    difficultyScore: 2,
    difficulty: "Easy",
    userDifficultyRatings: [2],
    description:
      "What is the expected value of the sum of two fair six-sided dice?",
    solution:
      "The expected value is the sum of the expected values of the two dice. Each die has an expected value of 3.5, so the total expected value is 7.",
    hints: [
      "Calculate the expected value of one die",
      "Sum the expected values of both dice",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Statistics", "Expected Value"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define expected value",
      "Calculate for one die",
      "Sum for two dice",
    ],
    isPro: false,
  },
  {
    id: 3,
    title: "Normal Between -1 and 1",
    difficultyScore: 5,
    difficulty: "Medium",
    userDifficultyRatings: [5],
    description:
      "Given a normally distributed variable with mean 0 and standard deviation 1, what is the probability that the variable takes a value between -1 and 1?",
    solution:
      "The probability is the area under the standard normal curve between -1 and 1, which is approximately 68.27%.",
    hints: [
      "Consider the properties of the standard normal distribution",
      "Look up the 68-95-99.7 rule",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Statistics", "Normal Distribution"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify mean and SD",
      "Use the 68-95-99.7 rule",
      "Calculate the probability",
    ],
    isPro: false,
  },
  {
    id: 4,
    title: "Bayes' Disease",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "A test for a disease is 99% accurate. If 0.5% of the population has the disease, what is the probability that a person who tested positive actually has the disease?",
    solution:
      "Using Bayes' theorem, P(Disease|Positive) = (P(Positive|Disease) * P(Disease)) / P(Positive). This calculates to approximately 33.2%.",
    hints: [
      "Use Bayes' theorem formula",
      "Consider the accuracy of the test and the base rate of the disease",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Bayes' Theorem"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify known probabilities",
      "Apply Bayes' theorem",
      "Calculate the result",
    ],
    isPro: true,
  },
  {
    id: 5,
    title: "Martingale Pitfalls",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Explain the martingale betting strategy and discuss its potential pitfalls.",
    solution:
      "The martingale strategy involves doubling your bet after every loss, aiming to recover all previous losses with one win. Pitfalls include the potential for significant losses and the limitation of finite bankrolls.",
    hints: [
      "Think about the doubling mechanism",
      "Consider the practical limitations",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Betting Strategy", "Finance"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the strategy",
      "Identify the mechanism",
      "Discuss potential pitfalls",
    ],
    isPro: false,
  },
  {
    id: 6,
    title: "Define Markov Chain",
    difficultyScore: 5,
    difficulty: "Medium",
    userDifficultyRatings: [5],
    description: "Define a Markov chain and give an example.",
    solution:
      "A Markov chain is a stochastic process where the next state depends only on the current state. Example: A random walk on a number line where you can move one step left or right with equal probability.",
    hints: [
      "Focus on the memoryless property",
      "Consider simple examples like random walks",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Stochastic Process", "Markov Chains"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define Markov chain",
      "Explain memoryless property",
      "Provide an example",
    ],
    isPro: true,
  },
  {
    id: 7,
    title: "Linear Regression in Finance",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "What is the purpose of linear regression and how is it used in finance?",
    solution:
      "Linear regression models the relationship between a dependent variable and one or more independent variables. In finance, it is used to predict stock prices, returns, and other financial metrics.",
    hints: [
      "Think about the relationship between variables",
      "Consider applications in finance",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Regression Analysis", "Finance"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define linear regression",
      "Explain its purpose",
      "Discuss its use in finance",
    ],
    isPro: false,
  },
  {
    id: 8,
    title: "Monte Carlo in Risk Management",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Describe how Monte Carlo simulations are used in risk management.",
    solution:
      "Monte Carlo simulations use random sampling to model the probability of different outcomes in financial processes, helping to assess risk and make informed decisions.",
    hints: [
      "Focus on random sampling",
      "Consider its applications in modeling uncertainty",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Risk Management", "Monte Carlo Simulation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define Monte Carlo simulation",
      "Explain random sampling",
      "Discuss its use in risk management",
    ],
    isPro: true,
  },
  {
    id: 9,
    title: "Black-Scholes Model Explanation",
    difficultyScore: 9,
    difficulty: "Hard",
    userDifficultyRatings: [9],
    description: "Explain the Black-Scholes model for option pricing.",
    solution:
      "The Black-Scholes model calculates the theoretical price of European-style options using factors such as the stock price, strike price, time to expiration, risk-free rate, and volatility.",
    hints: [
      "Understand the factors involved in the model",
      "Consider how these factors impact the option price",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Option Pricing", "Black-Scholes Model"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify the factors",
      "Apply the Black-Scholes formula",
      "Calculate the option price",
    ],
    isPro: true,
  },
  {
    id: 10,
    title: "Arbitrage in Financial Markets",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "What is arbitrage and how can it be exploited in financial markets?",
    solution:
      "Arbitrage is the simultaneous purchase and sale of an asset to profit from price differences. It is exploited by identifying mispricings in different markets or instruments.",
    hints: [
      "Think about price differences in markets",
      "Consider the mechanism of simultaneous buying and selling",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Arbitrage"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define arbitrage",
      "Identify price differences",
      "Explain the buying and selling mechanism",
    ],
    isPro: false,
  },
  {
    id: 11,
    title: "Max Subarray Sum Function",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Write a function to find the maximum sum of a contiguous subarray.",
    solution:
      "This is solved using Kadane's Algorithm. Initialize max_sum and current_sum to the first element. Traverse the array, updating current_sum to the maximum of the current element and current_sum + current element, and updating max_sum to the maximum of max_sum and current_sum.",
    hints: ["Use Kadane's Algorithm", "Keep track of current and maximum sums"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply Kadane's Algorithm",
      "Calculate the maximum sum",
    ],
    isPro: true,
  },
  {
    id: 12,
    title: "Stack vs Queue Differences",
    difficultyScore: 3,
    difficulty: "Easy",
    userDifficultyRatings: [3],
    description:
      "Explain the difference between a stack and a queue, and provide use cases for each.",
    solution:
      "A stack is a LIFO (Last In First Out) data structure, used for tasks like reversing a string. A queue is a FIFO (First In First Out) data structure, used for tasks like task scheduling.",
    hints: [
      "Focus on the order of operations",
      "Consider practical use cases for each",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Data Structures", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define stack and queue",
      "Explain LIFO and FIFO",
      "Provide use cases",
    ],
    isPro: false,
  },
  {
    id: 13,
    title: "Binary Search Implementation",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Implement binary search on a sorted array and explain its time complexity.",
    solution:
      "Binary search repeatedly divides the search interval in half. If the target value is less than the middle element, search the left half; otherwise, search the right half. Its time complexity is O(log n).",
    hints: [
      "Divide and conquer approach",
      "Understand the logarithmic time complexity",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Binary Search"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define binary search",
      "Implement the algorithm",
      "Explain time complexity",
    ],
    isPro: false,
  },
  {
    id: 14,
    title: "Fermat's Last Theorem Significance",
    difficultyScore: 9,
    difficulty: "Hard",
    userDifficultyRatings: [9],
    description:
      "State Fermat's Last Theorem and discuss its significance in mathematics.",
    solution:
      "Fermat's Last Theorem states that there are no whole number solutions to the equation x^n + y^n = z^n for n > 2. It remained unproven for centuries and was finally proven by Andrew Wiles in 1994.",
    hints: [
      "Focus on the equation x^n + y^n = z^n",
      "Consider the historical significance of the proof",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Mathematics", "Theorems"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "State the theorem",
      "Discuss the significance",
      "Explain the proof",
    ],
    isPro: false,
  },
  {
    id: 15,
    title: "Bridge Crossing Puzzle Solution",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Describe the solution to the problem where four people must cross a bridge at night with one flashlight and various crossing times.",
    solution:
      "The optimal solution involves minimizing the total crossing time by always having the two fastest cross together whenever possible and strategically returning the flashlight.",
    hints: [
      "Think about minimizing the total crossing time",
      "Strategize the use of the flashlight",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Puzzles", "Logical Thinking"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Strategize the crossing",
      "Minimize crossing time",
    ],
    isPro: true,
  },
  {
    id: 16,
    title: "Full Adder Circuit Logic",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Explain how a full adder circuit is constructed using basic logic gates.",
    solution:
      "A full adder can be constructed using two half adders and an OR gate. The first half adder adds the input bits, the second half adder adds the carry bit, and the OR gate combines the carry outputs.",
    hints: [
      "Understand the function of half adders",
      "Consider the role of the OR gate",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Electronics", "Logic Gates"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the full adder",
      "Explain the role of each gate",
      "Construct the circuit",
    ],
    isPro: true,
  },
  {
    id: 17,
    title: "Monty Hall Problem Strategy",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Explain the Monty Hall problem and the reasoning behind the optimal strategy.",
    solution:
      "The Monty Hall problem is a probability puzzle where switching doors after one is revealed increases the probability of winning from 1/3 to 2/3.",
    hints: [
      "Focus on the probability change after one door is revealed",
      "Understand the initial probabilities",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Probability", "Puzzles"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Explain the strategy",
      "Calculate the probabilities",
    ],
    isPro: true,
  },
  {
    id: 18,
    title: "3 Sets Tennis Game Bet",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "For a 3 sets tennis game, would you bet on it finishing in 2 sets or 3 sets?",
    solution:
      "Two sets - Let p=prob team 1 wins and q=prob team 2 wins. p^2 + q^2 = probability finish in two sets. 2*p*q = probability finish in three sets. p^2 + q^2 always >= 2*p*q, so the answer is two sets.",
    hints: [
      "Consider the probabilities of winning for each team",
      "Understand the conditions for finishing in two or three sets",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: false,
  },
  {
    id: 19,
    title: "Dots on a Square Edges",
    difficultyScore: 5,
    difficulty: "Medium",
    userDifficultyRatings: [5],
    description:
      "I have a square, and place three dots along the 4 edges at random. What is the probability that the dots lie on distinct edges?",
    solution:
      "3/8 - Given the edge the first dot is on, the probability the other two dots are on distinct edges is (3/4)*(2/4)",
    hints: [
      "Think about the placement of each dot",
      "Consider the remaining edges for each subsequent dot",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Geometry"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: false,
  },
  {
    id: 20,
    title: "Total Handshakes in a Room",
    difficultyScore: 3,
    difficulty: "Easy",
    userDifficultyRatings: [3],
    description:
      "You have 10 people in a room. How many total handshakes if they all shake hands?",
    solution:
      "45 - (10 choose 2) = 45 -- this is the total number of ways two people can shake hands.",
    hints: [
      "Use the combination formula",
      "Think about how each pair shakes hands",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Combinatorics", "Puzzles"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the combination formula",
      "Calculate the answer",
    ],
    isPro: false,
  },
  {
    id: 21,
    title: "Choose Deck for Red Cards",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Two decks of cards. One deck has 52 cards, the other has 104. You pick two cards separately from the same pack. If both of two cards are red, you win. Which pack will you choose?",
    solution:
      "104 card pack - (52/104)*(51/103) > (26/52)*(25/51), or 51/103 > 25/51",
    hints: [
      "Compare the probabilities for each deck",
      "Consider the total number of cards and red cards",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 22,
    title: "Simple Multiplication Trick",
    difficultyScore: 2,
    difficulty: "Easy",
    userDifficultyRatings: [2],
    description: "What is 39*41?",
    solution: "1599 - 39*41 = (40-1)*(40+1) = 40*40 - 1 = 1599",
    hints: [
      "Use the difference of squares formula",
      "Think about the factors (40-1) and (40+1)",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Mathematics", "Multiplication"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the formula",
      "Calculate the answer",
    ],
    isPro: true,
  },
  {
    id: 23,
    title: "Average Salary Without Disclosure",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "A group of people wants to determine their average salary on the condition that no individual would be able to find out anyone else's salary. Can they accomplish this, and, if so, how?",
    solution:
      "Yes, it’s possible - The first person thinks of a random number, say X. This person adds this number to her salary. The rest of the group simply adds their salary to the initial number. Then, the first person subtracts the random number X and divides the total salary sum by the size of the group to obtain the average.",
    hints: [
      "Consider using a random number",
      "Ensure privacy by using a sum of salaries",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Statistics", "Puzzles"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the method",
      "Calculate the average",
    ],
    isPro: true,
  },
  {
    id: 24,
    title: "Digits in 99 to the 99th",
    difficultyScore: 9,
    difficulty: "Hard",
    userDifficultyRatings: [9],
    description: "How many digits are in 99 to the 99th power?",
    solution:
      "198 - 99^99 = (100)^(99) * (.99)^99 = (10)^(198) * (.99)^99. You can convince yourself 10^198 has 199 digits, and 0.99^.99 approaches 1/e. Thus, (10)^(198) * (.99)^99 has 198 digits.",
    hints: [
      "Use logarithms to estimate the number of digits",
      "Consider the properties of powers and logarithms",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Mathematics", "Logarithms"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply logarithms",
      "Calculate the number of digits",
    ],
    isPro: true,
  },
  {
    id: 25,
    title: "Last Passenger Seat Probability",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "A line of 100 passengers is waiting to board a plane. They each hold a ticket to one of the 100 seats on that flight. Unfortunately, the first person in line is crazy, and will ignore the seat number on their ticket, picking a random seat to occupy. All of the other passengers are quite normal, and will go to their proper seat unless it is already occupied. If it is occupied, they will then find a free seat to sit in, at random. What is the probability that the last (100th) person to board the plane will sit in their proper seat (#100)?",
    solution:
      "0.5 - The fate of the last passenger is determined the second either the first or last seat on the plane is taken. This statement is true because the last person will either get the first seat or the last seat. All other seats will necessarily be taken by the time the last passenger gets to pick his/her seat. Since at each choice step, the first or last seat has an equal probability of being taken, the last person will get either the first or last with equal probability: 0.5.",
    hints: [
      "Consider the probabilities of the first and last seat being taken",
      "Think about the process for the last passenger",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Probability", "Puzzles"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 26,
    title: "Sum from 1 to 100",
    difficultyScore: 2,
    difficulty: "Easy",
    userDifficultyRatings: [2],
    description: "What is the sum of the numbers one to 100?",
    solution:
      "5050 - Sum of numbers from 1,2....n = n*(n+1)/2. You can also think about this problem by pairing off numbers - 1 and 100, 2 and 99, 3 and 98, 4 and 97, etc. We have 50 of these pairs, and each pair sums up to 101, so the final sum = 50*101 = 5050.",
    hints: [
      "Use the sum formula for the first n natural numbers",
      "Consider pairing the numbers to find the sum",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Mathematics", "Summation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the sum formula",
      "Calculate the answer",
    ],
    isPro: true,
  },
  {
    id: 27,
    title: "Measure 4 Gallons Water",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "You have a 3 gallon jug and 5 gallon jug, how do you measure out exactly 4 gallons? Is this possible?",
    solution:
      "Yes, it’s possible - Fill up the 3 gallon jug. Then, pour the liquid into the 5 gallon jug. Fill the 3 gallon jug again, and then fill the 5 gallon jug until it is full. We now have 1 gallon remaining in the 3 gallon jug. We empty the five gallon jug and pour the remaining 1 gallon into our 5 gallon jug. Finally, we fill the 3 gallon jug and add this to the 5 gallon jug (which already had 1 gallon). We are left with 4 gallons in the 5 gallon jug.",
    hints: [
      "Consider the steps to measure using both jugs",
      "Think about transferring water between the jugs",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Puzzles", "Logic"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the method",
      "Measure the water",
    ],
    isPro: true,
  },
  {
    id: 28,
    title: "Probability of More Heads",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "You have 17 coins and I have 16 coins, we flip all coins at the same time. If you have more heads then you win, if we have the same number of heads or if you have less then I win. What's your probability of winning?",
    solution:
      "0.5 - Use recursion - The initial 16 flips have the same probability of everything. Thus, the game completely depends on if the last coin flip is tails or head (50/50 chance of H vs. T).",
    hints: [
      "Focus on the probability of the final flip",
      "Consider the initial 16 flips and their probabilities",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 29,
    title: "Same Color Cards",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "What is the probability you draw two cards of the same color from a standard 52-card deck? You are drawing without replacement.",
    solution:
      "25/51 - You either draw a black or a red card first. Then, there are 51 cards left in the deck and 25 of these cards have the same color. Thus, the probability is 25/51.",
    hints: [
      "Consider the probabilities after drawing the first card",
      "Think about the remaining cards and their colors",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 30,
    title: "Light Switches and Bulbs",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "You’re in a room with three light switches, each of which controls one of three light bulbs in the next room. You need to determine which switch controls which bulb. All lights are off to begin, and you can’t see into one room from the other. You can inspect the other room only once. How can you find out which switches are connected to which bulbs? Is this possible?",
    solution:
      "Yes, it’s possible - Leave switch 1 off. Then, turn switch 2 on for ten minutes. After the ten minutes, turn it off and quickly turn on switch 3. Now, go into the room. The currently lit up bulb connects to switch 3. The bulb that off but still warm is from switch 2, and the remaining bulb is from switch 1.",
    hints: [
      "Consider the states of the bulbs when you enter the room",
      "Think about the effects of time on the bulbs",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Brain Teasers and Logical Puzzles",
    tags: ["Puzzles", "Logic"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply the method",
      "Identify the bulbs",
    ],
    isPro: true,
  },
  {
    id: 31,
    title: "World Series 7 Games Odds",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "In world series, what are the odds it goes 7 games if each team equal chance of winning?",
    solution:
      "20/64 - Out of the first three games, each team needs to win three. Thus, (6 choose 3)*(.5^6) = 20/64, as each team has a 1/2 probability of winning each game.",
    hints: [
      "Use the combination formula for the first three games",
      "Consider the probabilities of each game being won",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 32,
    title: "Even Heads in 100 Flips",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Given 100 coin flips, what is the probability that you get an even number of heads?",
    solution:
      "1/2 - Whether there is an odd or even number of heads is ultimately determined by the final flip (50/50 chance of being heads vs. tails), for any number of flips.",
    hints: [
      "Focus on the probability of the final flip",
      "Consider the total number of flips and their probabilities",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 33,
    title: "Non-adjacent Balls",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "There are 5 balls, 3 red, and 2 black. What is the probability that a random ordering of the 5 balls does not have the 2 black balls next to each other?",
    solution:
      "0.6 - Because of repeats of black/red balls, there are 10 combinations of red/black balls: (5 choose 2) or (5 choose 3) spots to put the black or red balls, respectively. There are 4 places that 2 black balls can be next to each other, so the other 6 combinations do NOT have two black balls next to each other.",
    hints: [
      "Use the combination formula for the ball positions",
      "Consider the probability of the black balls being next to each other",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Probability", "Games"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Calculate probabilities",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 34,
    title: "Least Multiple of 15",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "What is the least multiple of 15 whose digits consist only of 1's and 0's?",
    solution:
      "1110 - The last digit must be zero (30, 45, 60, 75, etc.). Multiples of 15 never end in 1. Then, starting checking numbers. 10, 100, 110, 1000, 1100, 1110. You will quickly arrive at the answer if you are good with your mental math.",
    hints: [
      "Consider the properties of multiples of 15",
      "Think about the digits being 1's and 0's",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Mathematics", "Multiples"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Identify possible multiples",
      "Determine the answer",
    ],
    isPro: true,
  },
  {
    id: 35,
    title: "Is 1027 Prime?",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description: "Is 1027 a prime number?",
    solution:
      "No - 1027 = 1000 + 27 = 10^3 + 3^3. We know a^3 + b^3 can be factored, so 1027 is NOT prime.",
    hints: [
      "Consider the factorization of 1027",
      "Think about the properties of prime numbers",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Critical Mathematical Foundations",
    tags: ["Mathematics", "Prime Numbers"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the problem",
      "Apply factorization",
      "Determine if prime",
    ],
    isPro: true,
  },
  {
    id: 36,
    title: "Two Sum Problem",
    difficultyScore: 5,
    difficulty: "Medium",
    userDifficultyRatings: [5],
    description:
      "Find two numbers in an array that sum up to a specific target.",
    solution:
      "Use a hashmap to store the difference of each element and the target. If the complement exists in the hashmap, return the indices.",
    hints: ["Use a hashmap to store elements", "Check for complement"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize a hashmap",
      "Iterate through the array",
      "Check for complement and return indices",
    ],
    isPro: false,
  },
  {
    id: 37,
    title: "Merge Intervals",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Given a collection of intervals, merge all overlapping intervals.",
    solution:
      "Sort the intervals by the start time. Iterate through the intervals, merging overlapping ones.",
    hints: ["Sort intervals by start time", "Iterate and merge"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Sort intervals",
      "Initialize merged array",
      "Iterate and merge intervals",
    ],
    isPro: true,
  },
  {
    id: 38,
    title: "Longest Increasing Subsequence",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Find the length of the longest increasing subsequence in an array.",
    solution:
      "Use dynamic programming to build up the solution by comparing each element with all previous elements.",
    hints: ["Use dynamic programming", "Compare each element"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize DP array",
      "Iterate and update DP values",
      "Find the maximum value in DP array",
    ],
    isPro: true,
  },
  {
    id: 39,
    title: "Valid Parentheses",
    difficultyScore: 4,
    difficulty: "Easy",
    userDifficultyRatings: [4],
    description:
      "Determine if a string containing just the characters '(', ')', '{', '}', '[' and ']' is valid.",
    solution:
      "Use a stack to keep track of opening brackets. Match them with closing brackets.",
    hints: ["Use a stack", "Match opening and closing brackets"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize a stack",
      "Iterate through characters",
      "Match brackets using stack",
    ],
    isPro: false,
  },
  {
    id: 40,
    title: "Binary Tree Level Order Traversal",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description: "Return the level order traversal of a binary tree.",
    solution: "Use a queue to traverse the tree level by level.",
    hints: ["Use a queue", "Traverse level by level"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize a queue",
      "Traverse tree using queue",
      "Collect nodes at each level",
    ],
    isPro: true,
  },
  {
    id: 41,
    title: "Kth Largest Element in an Array",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description: "Find the Kth largest element in an array.",
    solution: "Use a min-heap to keep track of the K largest elements.",
    hints: ["Use a min-heap", "Maintain size of K"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize a min-heap",
      "Add elements to heap",
      "Maintain heap size of K",
    ],
    isPro: true,
  },
  {
    id: 42,
    title: "Search in Rotated Sorted Array",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description: "Search for a target value in a rotated sorted array.",
    solution: "Use modified binary search to handle the rotated part.",
    hints: ["Use binary search", "Account for rotation"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize binary search",
      "Adjust for rotation",
      "Search for target",
    ],
    isPro: true,
  },
  {
    id: 43,
    title: "Palindrome Partitioning",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Partition a string such that every substring is a palindrome.",
    solution:
      "Use backtracking to explore all possible partitions and check for palindromes.",
    hints: ["Use backtracking", "Check for palindromes"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize backtracking function",
      "Partition and check palindromes",
      "Collect valid partitions",
    ],
    isPro: true,
  },
  {
    id: 44,
    title: "Word Ladder",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Transform one word into another by changing only one letter at a time.",
    solution:
      "Use breadth-first search (BFS) to explore all possible transformations.",
    hints: ["Use BFS", "Transform one letter at a time"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize BFS",
      "Explore transformations",
      "Track transformation steps",
    ],
    isPro: true,
  },
  {
    id: 45,
    title: "Maximum Product Subarray",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Find the contiguous subarray within an array which has the largest product.",
    solution:
      "Use dynamic programming to keep track of the maximum and minimum products up to the current position.",
    hints: ["Use dynamic programming", "Track maximum and minimum products"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Programming and Algorithmic Thinking",
    tags: ["Algorithms", "Programming"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Initialize DP arrays",
      "Iterate and update products",
      "Find the maximum product",
    ],
    isPro: true,
  },
  {
    id: 46,
    title: "Calculate Bond Price",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Calculate the price of a bond with a face value of $1000, a coupon rate of 5%, and a maturity of 10 years if the market interest rate is 4%.",
    solution:
      "The bond price is calculated by discounting the future cash flows (coupon payments and face value) at the market interest rate. The formula is: Price = (C * (1 - (1 + r)^-n) / r) + (F / (1 + r)^n), where C is the coupon payment, r is the market interest rate, n is the number of periods, and F is the face value.",
    hints: [
      "Use the bond pricing formula",
      "Discount the future cash flows at the market interest rate",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Bonds"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify the cash flows",
      "Apply the discounting formula",
      "Calculate the bond price",
    ],
    isPro: true,
  },
  {
    id: 47,
    title: "Black-Scholes Implied Volatility",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Explain how to compute the implied volatility using the Black-Scholes model.",
    solution:
      "Implied volatility is found by solving the Black-Scholes formula for volatility, given the market price of the option. This typically requires numerical methods such as Newton-Raphson iteration.",
    hints: [
      "Understand the inputs to the Black-Scholes model",
      "Use numerical methods to solve for volatility",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Black-Scholes Model"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the Black-Scholes formula",
      "Identify the market inputs",
      "Apply numerical methods to solve",
    ],
    isPro: true,
  },
  {
    id: 48,
    title: "CAPM Expected Return",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Calculate the expected return of a stock using the Capital Asset Pricing Model (CAPM). Assume a risk-free rate of 2%, a market return of 8%, and a beta of 1.2.",
    solution:
      "The CAPM formula is: Expected Return = Risk-Free Rate + Beta * (Market Return - Risk-Free Rate). Substituting the given values: Expected Return = 2% + 1.2 * (8% - 2%) = 9.2%.",
    hints: [
      "Use the CAPM formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "CAPM"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the CAPM formula",
      "Identify the inputs",
      "Calculate the expected return",
    ],
    isPro: true,
  },
  {
    id: 49,
    title: "Duration of a Bond",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Calculate the Macaulay duration of a 5-year bond with a face value of $1000, a coupon rate of 6%, and a yield to maturity of 5%.",
    solution:
      "Macaulay duration is the weighted average time until cash flows are received. It is calculated using the formula: Duration = (1 + y) / y - (1 + y + n * (C - y)) / (C * (1 + y)^n - 1), where y is the yield to maturity, C is the coupon payment, and n is the number of periods.",
    hints: [
      "Use the Macaulay duration formula",
      "Calculate the weighted average time until cash flows",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Bonds"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Identify the cash flows",
      "Apply the duration formula",
      "Calculate the duration",
    ],
    isPro: true,
  },
  {
    id: 50,
    title: "Monte Carlo for Portfolio Risk",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Describe how Monte Carlo simulations can be used to assess the risk of an investment portfolio.",
    solution:
      "Monte Carlo simulations model the probability of different outcomes by generating a large number of random samples from the input distributions (e.g., asset returns). The results provide a distribution of potential portfolio values, helping to assess risk.",
    hints: [
      "Use random sampling to model different outcomes",
      "Generate a large number of simulations",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Monte Carlo Simulation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the input distributions",
      "Generate random samples",
      "Analyze the distribution of outcomes",
    ],
    isPro: true,
  },
  {
    id: 51,
    title: "Risk-Adjusted Return",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Calculate the Sharpe ratio of a portfolio with an expected return of 10%, a risk-free rate of 2%, and a standard deviation of returns of 15%.",
    solution:
      "The Sharpe ratio is calculated using the formula: Sharpe Ratio = (Expected Return - Risk-Free Rate) / Standard Deviation. Substituting the given values: Sharpe Ratio = (10% - 2%) / 15% = 0.533.",
    hints: [
      "Use the Sharpe ratio formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Risk Management"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the Sharpe ratio formula",
      "Identify the inputs",
      "Calculate the Sharpe ratio",
    ],
    isPro: true,
  },
  {
    id: 52,
    title: "Dividend Discount Model",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Calculate the intrinsic value of a stock using the Dividend Discount Model (DDM) with an expected dividend of $2, a growth rate of 3%, and a required rate of return of 8%.",
    solution:
      "The DDM formula is: Intrinsic Value = Dividend per share / (Required rate of return - Growth rate). Substituting the given values: Intrinsic Value = $2 / (0.08 - 0.03) = $40.",
    hints: [
      "Use the Dividend Discount Model formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Valuation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the DDM formula",
      "Identify the inputs",
      "Calculate the intrinsic value",
    ],
    isPro: true,
  },
  {
    id: 53,
    title: "Hedge Ratio Calculation",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Calculate the hedge ratio for a portfolio of stocks using futures contracts. Assume the portfolio value is $1,000,000, the futures contract value is $100,000, and the portfolio beta is 1.2.",
    solution:
      "The hedge ratio is calculated using the formula: Hedge Ratio = Portfolio Value / (Futures Contract Value * Beta). Substituting the given values: Hedge Ratio = $1,000,000 / ($100,000 * 1.2) = 8.33.",
    hints: [
      "Use the hedge ratio formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Risk Management"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the hedge ratio formula",
      "Identify the inputs",
      "Calculate the hedge ratio",
    ],
    isPro: true,
  },
  {
    id: 54,
    title: "Option Greeks: Delta Calculation",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Calculate the Delta of a European call option given the following parameters: stock price = $100, strike price = $95, time to maturity = 1 year, risk-free rate = 5%, and volatility = 20%.",
    solution:
      "Delta (Δ) for a European call option is calculated using the Black-Scholes formula: Δ = N(d1), where d1 = (ln(S/K) + (r + (σ²/2)) * t) / (σ * sqrt(t)).",
    hints: [
      "Use the Black-Scholes formula",
      "Calculate d1 and apply the cumulative distribution function (N)",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Options"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the Black-Scholes formula",
      "Calculate d1",
      "Apply the cumulative distribution function",
    ],
    isPro: true,
  },
  {
    id: 55,
    title: "Duration and Convexity",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Explain how duration and convexity are used to estimate the price change of a bond given a change in interest rates.",
    solution:
      "Duration measures the sensitivity of a bond's price to changes in interest rates. Convexity accounts for the curvature in the price-yield relationship. The price change is estimated using: ΔP = -Duration * Δy + (1/2) * Convexity * (Δy)^2.",
    hints: [
      "Use the duration and convexity formulas",
      "Estimate the price change for small interest rate changes",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Bonds"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define duration and convexity",
      "Calculate the price change",
      "Apply the formula",
    ],
    isPro: true,
  },
  {
    id: 56,
    title: "Portfolio Variance Calculation",
    difficultyScore: 7,
    difficulty: "Hard",
    userDifficultyRatings: [7],
    description:
      "Calculate the variance of a two-asset portfolio with weights of 0.6 and 0.4, variances of 0.04 and 0.09, and a covariance of 0.02.",
    solution:
      "The portfolio variance is calculated using: σ²_p = w₁²σ₁² + w₂²σ₂² + 2w₁w₂Cov₁₂. Substituting the given values: σ²_p = (0.6² * 0.04) + (0.4² * 0.09) + (2 * 0.6 * 0.4 * 0.02) = 0.0464.",
    hints: [
      "Use the portfolio variance formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Portfolio Management"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the portfolio variance formula",
      "Identify the inputs",
      "Calculate the variance",
    ],
    isPro: true,
  },
  {
    id: 57,
    title: "Forward Rate Agreement",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Calculate the payoff of a forward rate agreement (FRA) given a notional principal of $1,000,000, a contract rate of 5%, and a settlement rate of 4.5% over a period of 180 days.",
    solution:
      "The payoff of an FRA is calculated using: Payoff = Notional Principal * (Contract Rate - Settlement Rate) * (Days/360). Substituting the given values: Payoff = $1,000,000 * (0.05 - 0.045) * (180/360) = $2,500.",
    hints: [
      "Use the FRA payoff formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Derivatives"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the FRA payoff formula",
      "Identify the inputs",
      "Calculate the payoff",
    ],
    isPro: true,
  },
  {
    id: 58,
    title: "Value at Risk (VaR) Calculation",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Calculate the Value at Risk (VaR) of a portfolio with a mean return of 5%, a standard deviation of 10%, and a confidence level of 95%.",
    solution:
      "VaR is calculated using the formula: VaR = Z * σ * √T, where Z is the Z-score for the confidence level, σ is the standard deviation, and T is the time period. For a 95% confidence level, Z = 1.645. Substituting the given values: VaR = 1.645 * 0.10 * √1 = 0.1645 or 16.45%.",
    hints: [
      "Use the VaR formula",
      "Identify the Z-score for the confidence level",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Risk Management"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the VaR formula",
      "Identify the inputs",
      "Calculate the VaR",
    ],
    isPro: true,
  },
  {
    id: 59,
    title: "Discounted Cash Flow (DCF) Valuation",
    difficultyScore: 6,
    difficulty: "Medium",
    userDifficultyRatings: [6],
    description:
      "Calculate the intrinsic value of a company using the Discounted Cash Flow (DCF) method with free cash flows of $10 million, $12 million, and $14 million over 3 years, and a discount rate of 8%.",
    solution:
      "The DCF formula is: Intrinsic Value = Σ (Free Cash Flow / (1 + Discount Rate)^t). Substituting the given values: Intrinsic Value = $10M / (1 + 0.08)^1 + $12M / (1 + 0.08)^2 + $14M / (1 + 0.08)^3 = $9.26M + $10.29M + $11.11M = $30.66M.",
    hints: ["Use the DCF formula", "Discount the free cash flows"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Valuation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the DCF formula",
      "Identify the free cash flows",
      "Calculate the intrinsic value",
    ],
    isPro: true,
  },
  {
    id: 60,
    title: "Profitability Index Calculation",
    difficultyScore: 5,
    difficulty: "Medium",
    userDifficultyRatings: [5],
    description:
      "Calculate the profitability index of a project with initial investment of $200,000 and present value of future cash flows of $250,000.",
    solution:
      "The profitability index is calculated using the formula: Profitability Index = Present Value of Future Cash Flows / Initial Investment. Substituting the given values: Profitability Index = $250,000 / $200,000 = 1.25.",
    hints: [
      "Use the profitability index formula",
      "Substitute the given values into the formula",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Project Evaluation"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the profitability index formula",
      "Identify the inputs",
      "Calculate the profitability index",
    ],
    isPro: true,
  },
  {
    id: 61,
    title: "Arbitrage-Free Bond Pricing",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Explain how to determine if a bond is priced without arbitrage using the law of one price.",
    solution:
      "A bond is priced without arbitrage if the present value of its cash flows, discounted at the appropriate spot rates, equals its market price. Using the law of one price, ensure that no arbitrage opportunities exist by comparing with similar instruments.",
    hints: [
      "Use the law of one price",
      "Discount cash flows at appropriate spot rates",
    ],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Bonds"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the law of one price",
      "Identify the spot rates",
      "Check for arbitrage opportunities",
    ],
    isPro: true,
  },
  {
    id: 62,
    title: "Interest Rate Swap Valuation",
    difficultyScore: 8,
    difficulty: "Hard",
    userDifficultyRatings: [8],
    description:
      "Calculate the value of an interest rate swap with a notional principal of $1,000,000, a fixed rate of 5%, a floating rate of 4%, and a time to maturity of 1 year.",
    solution:
      "The value of an interest rate swap is the difference between the present value of the fixed rate payments and the present value of the floating rate payments. The formula is: Swap Value = Notional Principal * (Fixed Rate - Floating Rate) * (1 + Floating Rate) / (1 + Fixed Rate). Substituting the given values: Swap Value = $1,000,000 * (0.05 - 0.04) * (1 + 0.04) / (1 + 0.05) = $9,523.81.",
    hints: ["Use the swap valuation formula", "Discount the cash flows"],
    completed: false,
    correct: null,
    attempts: 0,
    timeSpent: 0,
    category: "Financial Concepts and Modeling",
    tags: ["Finance", "Derivatives"],
    userFeedback: [],
    averageTimeToSolve: null,
    interactiveSteps: [
      "Define the swap valuation formula",
      "Identify the cash flows",
      "Calculate the swap value",
    ],
    isPro: true,
  },
];
